<template>
  <div class="container">
    <div class="nei_box">
      <div class="title_box">{{ xq.title }}</div>
      <div class="shi_ping_box">
        <div class="ping_box">
          <video
            :preload="preload"
            :poster="videoImg"
            :height="height"
            :width="width"
            align="center"
            :controls="controls"
            :autoplay="autoplay"
          >
            <!--<source :src="videosrc" type="video/mp4">-->
            <source :src="videosrc | ImgFilter" type="video/mp4" />
          </video>

          <div class="text_jian">
            <div class="vedi_text">视频简介</div>
            <div class="vedio_content">
              {{ xq.lesson_details }}
            </div>
          </div>
        </div>
        <div class="kan_text">{{ xq.views }}次观看</div>
      </div>
      <div class="bottom">
        
        <el-tabs v-model="activeName">
          <el-tab-pane label="视频详情" name="first">
            <div class="jiang_text">
              {{ xq.video_details }}
            </div></el-tab-pane
          >
          <el-tab-pane label="导师简介" name="second"> 
            <div class="jiang_text">
              {{ xq.teachers }}
            </div></el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { LessonXq } from "@/common/js/api";
export default {
  name: "kechengxq",
  data() {
    return {
      videoSrc: "../../../assets/video/one.mp4",
      videoImg: "../../../../static/full_res.jpg",
      playStatus: "",
      muteStatus: "",
      isMute: true,
      isPlay: false,
      width: "820", // 设置视频播放器的显示宽度（以像素为单位）
      height: "500", // 设置视频播放器的显示高度（以像素为单位）
      preload: "auto", //  建议浏览器是否应在<video>加载元素后立即开始下载视频数据。
      controls: true, // 确定播放器是否具有用户可以与之交互的控件。没有控件，启动视频播放的唯一方法是使用autoplay属性或通过Player API。
      autoplay: true,
      videosrc: "",
      xq: {},
      activeName: "first",
    };
  },
  created() {
    this.videosrc = this.$route.query.video;
    this.id = this.$route.query.id;
    this.LessonXq();
  },
  filters: {
    ImgFilter(value) {
      return "http://hout.kehui.cloud" + value;
    },
  },
  // 自动播放属性,muted:静音播放
  // autoplay: 'muted',
  methods: {
    // 课程详情
    LessonXq() {
      LessonXq({ id: this.id })
        .then((res) => {
          // console.log(res);
          this.xq = res.data;
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped lang="less">
video {
  margin: 15px;
  width: 863px;
  height: 454px;
}
.container {
  font-size: 14px;
  background-color: #f8f9fa;
  width: 100%;
  height: auto;
  overflow: hidden;
  margin-top: 135px;
  .nei_box {
    width: 1200px;
    background-color: #fff;
    // height: 869px;
    margin: 30px auto;
    overflow: hidden;
    .title_box {
      margin: 30px;
      color: rgba(0, 5, 10, 100);
      font-size: 20px;
    font-weight: 700;
    }
    .shi_ping_box {
      margin: 0 auto;
      width: 1132px;
      height: 516px;
      line-height: 20px;
      background-color: #0d0d0d;
      border: 1px solid rgba(187, 187, 187, 100);
      .kan_text {
        margin-left: 30px;
        color: rgba(195, 195, 195, 100);
        font-size: 14px;
      }
      .ping_box {
        display: flex;
      }
      .text_jian {
        margin: 30px auto 0;
        width: 196px;
        height: 450px;
        color: rgba(255, 255, 255, 100);
        .vedi_text {
          font-size: 18px;
          margin-bottom: 20px;
        }
        .vedio_content {
          font-size: 14px;
          line-height: 1.5;
          white-space: pre-line;
        }
      }
    }
    .bottom {
      margin: 15px 30px;
      
      .jiang_text {
        white-space: pre-line;
        margin-top: 10px;
        line-height: 1.5;
      }
    }
  }
}
@media screen and (max-width: 1200px) {
	.container{
		margin-top: 15rem;
	}
}
</style>